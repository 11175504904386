<template>
  <v-dialog max-width="500" persistent v-model="dialog">
    <v-card>
      <v-card-title class="headline">{{ title }}</v-card-title>
      <slot>
        <v-card-text>{{ text }}</v-card-text>
      </slot>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close">Abbrechen</v-btn>
        <v-btn :loading="isProcessing" @click="proceed" color="primary"
          >Ok</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmModal',
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    title: {
      type: String,
      default: 'Aktion bestätigen',
    },
    text: {
      type: String,
      required: true,
    },
    okAction: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isProcessing: false,
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        return this.$emit('input', value)
      },
    },
  },
  methods: {
    close() {
      this.dialog = false
    },
    async proceed() {
      this.isProcessing = true
      await this.okAction()
      this.close()
      this.isProcessing = false
    },
  },
}
</script>

<style scoped></style>
