<template>
  <v-dialog :fullscreen="true" @keydown.esc="dialog = false" v-model="dialog">
    <v-card class="d-flex" style="flex-direction: column" v-if="extractionData">
      <v-flex shrink style="flex: 0 1 auto !important">
        <v-toolbar color="primary" dark>
          <v-toolbar-title>{{
            extractionData.pdf | extractNameFromPdf
          }}</v-toolbar-title>
          <v-spacer />
          <v-btn @click="dialog = false" dark icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-flex>
      <pdf-viewer :extraction-data="extractionData" :xs6="false" />
    </v-card>
  </v-dialog>
</template>

<script>
import PdfViewer from '@/components/pdf/PDFViewer'
import { extractNameFromPdf } from '@/utils/pdfUtils'

export default {
  name: 'ModalPdfFullscreen',
  components: { PdfViewer },
  props: ['value'],
  computed: {
    dialog: {
      get() {
        return Boolean(this.extractionData)
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    extractionData() {
      return this.value
    },
  },
  filters: {
    extractNameFromPdf,
  },
}
</script>

<style scoped></style>
