<template>
  <app-layout>
    <v-container grid-list-md>
      <v-layout>
        <v-flex>
          <v-card>
            <DocumentListing
              ref="documentListing"
              :options="{ type: 'archived', hasDue: false }"
              @selectItem="(item) => (extractionDataId = item.id)"
              title="Dokumentenarchiv"
            >
              <template v-slot:actions="props">
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-if="isEditor"
                      @click.stop="archiveId = props.item.id"
                      icon
                      v-on="on"
                    >
                      <v-icon>unarchive</v-icon>
                    </v-btn>
                  </template>
                  <span>In Posteingang verschieben</span>
                </v-tooltip>
              </template>
            </DocumentListing>
          </v-card>
        </v-flex>
      </v-layout>
      <confirm-modal
        :ok-action="unarchive(archiveId)"
        text="Wollen Sie dieses Dokument wirklich zurück in den Posteingang verschieben?"
        v-model="archiveModal"
      />
      <modal-pdf-fullscreen v-model="extractionData" />
    </v-container>
  </app-layout>
</template>

<script>
import documentsApi from '@/api/documentsApi'
import AppLayout from '@/components/AppLayout'
import ConfirmModal from '@/components/modals/ConfirmModal'
import ModalPdfFullscreen from '@/components/pdf/ModalPDFFullscreen'
import DocumentListing from '@/pages/extractionView/DocumentListing'

export default {
  name: 'Archive',
  components: {
    DocumentListing,
    ModalPdfFullscreen,
    ConfirmModal,
    AppLayout,
  },
  props: ['filterBy', 'hasDue'],
  data() {
    return {
      archiveId: null,
      extractionDataId: null,
      extractionData: null,
    }
  },
  computed: {
    archiveModal: {
      get() {
        return Boolean(this.archiveId)
      },
      set() {
        this.archiveId = null
      },
    },
    isEditor() {
      return this.$can(this.$permissions.addPaymentData)
    },
  },
  methods: {
    unarchive(id) {
      return async () => {
        await documentsApi.unarchiveDocument(id)
        this.archiveId = null
        this.$refs.documentListing.loadPage(0)
      }
    },
  },
  watch: {
    async extractionDataId(id) {
      if (id) {
        this.extractionData = await documentsApi.getEntry(id)
      } else {
        this.extractionData = null
      }
    },
  },
}
</script>

<style scoped></style>
